.textalign-center {
    text-align: center;
  }
  
  .seat {
    background-color: Teal  !important;
  }
  
  .seat--reserved {
    background-color: Silver   !important;
  }
  
  .seat--selected {
    background-color: purple !important;
  }
  
  .seat-picker {
    margin: auto;
  }
  
/*   .seat-picker__row {
  }
  
  .seat-picker__row__number {
  } */
  
  div.blank {
    display: none;
  }

  .div-reservation {
    background-color: whitesmoke;
    padding-top: 8px;
    padding-bottom: 10px;
    padding-left: 2px;
    padding-right: 14px;
  }